import { Col, Row, Typography } from 'antd';
import snapdocsLogo from './images/snapdocs_logo.svg';
import poweredBy from './images/powered_by.svg';
import React, { useContext } from 'react';
import { ApplicationConfigurationContext } from './contexts/ApplicationConfigurationProvider';

const { Text } = Typography;

function FooterLogo() {
  const { applicationConfiguration } = useContext(ApplicationConfigurationContext);

  return (
    <>
      <Row justify="center">
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <img src={poweredBy} alt="Powered by" style={{ height: 'auto' }} />
        </Col>
        <Col flex="10px" />
        <Col>
          <img src={snapdocsLogo} alt="Snapdocs" width="152" height="28" />
        </Col>
      </Row>
      {applicationConfiguration.app_version && (
        <Row justify="center">
          <Text style={{ fontSize: '12px', paddingTop: '1em' }}>
            MISMO e-Eligibility Exchange Version {applicationConfiguration.app_version}
          </Text>
        </Row>
      )}
    </>
  );
}
export default FooterLogo;
